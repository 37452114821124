<template>
    <div class="section">
        <div class="columns">
            <div class="column is-four-fifths">
                <div class="content">
                    <h1>{{ $t('changelog.header') }}</h1>
                    <hr />
                    <article
                        v-for="(change, index) in changes"
                        :key="index"
                        class="content"
                    >
                        <p class="title">
                            {{ change.date }}
                            <b-tag type="is-info">
                                v{{ change.version }}
                            </b-tag>
                        </p>
                        <p class="subtitle">
                            {{ change.title }}
                        </p>
                        <ul>
                            <li v-for="(item, i) in change.items" :key="i">
                                <dd>{{ item }}</dd>
                            </li>
                        </ul>
                    </article>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Changelog',
    data() {
        return {
            changes: [
                {
                    date: '2020-12-06',
                    version: '0.1.2',
                    title: 'Sammanslagna ändringar sedan sist',
                    items: [
                        'FIX: Inaktiverad användares status synkat mot nya DB',
                        'FIX: Mäklare sorteras nu efter deras namn istället för deras kontor under ny order',
                        'FIX: Enter-knappen fungerar i login-rutan igen.',
                        'FIX: Lagt in events i bookingslistan (reservationer, blockeringar, noteringar)',
                        'FIX: Produktfärgen visades inte på bookingskortet i översikten',
                        'NYTT: Påbörjat separat sida för att fylla i faktureringsuppgifter per kontor'
                    ]
                },
                {
                    date: '2020-08-19',
                    version: '0.1.1',
                    title: 'Här kommer det finnas versionshistorik',
                    items: [
                        'BUG: Pris & moms i produktlistan samt visa befintliga'
                    ]
                }
            ]
        }
    }
}
</script>

<style scoped>
.is-four-fifths {
    margin: 0 auto;
}
</style>
